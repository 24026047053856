import React from 'react';
import HomePage from './pages/HomePage/HomePage';
import GlobalStyle from './globalStyles';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Form from "./components/Form/Form";
function Signup() {
	return (
		<>
			<GlobalStyle />
			<Navbar></Navbar>
			<Form /> 
			<Footer />		
		</>

	);
}

export default Signup;
