// export const URL = "https://lokalai9-jun.vercel.app";


export const URL = "http://localhost:5000";

export const FrontEnd_URL = "https://lokalai.com";




