import React from 'react';
import HomePage from './pages/HomePage/HomePage';
import GlobalStyle from './globalStyles';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

function Introduction() {
	return (
		<>
			<GlobalStyle />
			<Navbar></Navbar>
			<HomePage /> 
			<Footer />		
		</>

	);
}

export default Introduction;
