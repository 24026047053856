export const heroOne = {
  id: "mission",
  inverse: true,
  bigImage: true,
  topLine: {
    text: "Lokalai",
    extraText: "MISSION",
  },
  headline: "",
  description:
    "To use advanced information technology such as artificial intelligent, blockchain and non-fungible token to supercharge retail trade enterprises.",
  buttonLabel: "Business Plan",
  imgStart: "start",
  img: "./images/bg3.png",
  bottomImg: "",
  start: "true",
};

export const heroTwo = {
  id: "vision",

  reverse: true,

  lightBg: false,
  topLine: {
    text: "Lokalai",
    extraText: "VISION",
  },
  headline: "",
  description: "By 2033. we aim to help 1 million small and micro retail trade enterprises to be profitable, and to serve 100 million customers",
  buttonLabel: "Business Plan",
  linkTo: "/more",
  imgStart: "",
  img: "./images/bgnft.jpg",
  bottomImg: "",
  alt: "Vault",
  start: "true",
};

export const heroThree = {
  id: "core value",
  inverse: true,
  bigImage: true,
  topLine: {
    text: "Lokalai",
    extraText: "Core Value",
  },
  headline: "Continuous Innovation",
  description:
    "Take risk, if you win, you will be happy; if you lose, you will be wise",
  buttonLabel: "Learn More",
  imgStart: "start",
  img: "./images/operating.jpg",
  bottomImg: "",
  start: "true",
};

export const heroFour = {
  id: "services",

  reverse: true,

  lightBg: false,
  topLine: {
    text: "Lokalai",
    extraText: "Core Value",
  },
  headline: "Customer-centric",
  description: "Customer first, employees second, shareholders third",
  buttonLabel: "Learn More",
  linkTo: "/more",
  imgStart: "",
  img: "./images/market.jpg",
  bottomImg: "",
  alt: "Vault",
  start: "true",
};

export const heroFive = {
  id: "products",

  bigImage: true,
  smallSection: true,
  inverse: true,
  topLine: {
    text: "Lokalai",
    extraText: "Core Value",
  },
  headline: "Build Trust Value",
  description:
    "Lokalai was created to enhance convenient for retail enterprises and customers",
  buttonLabel: "Learn More",
  linkTo: "/download",
  imgStart: "",
  img: "./images/global.jpg",
  bottomImg: "",
  alt: "Vault",
  start: "true",
};

export const pricingData = [
  {
    title: "Individual",
    price: "$14.99/month after offer period",
    numAcc: "1 account",
    features: [
      "Listen to music ad-free",
      "Play anywhere - even offline",
      "On-demand playback",
    ],
  },
  {
    title: "Duo",
    price: "$18.99/month after offer period",
    numAcc: "2 accounts",
    features: [
      "2 Premium accounts for a couple under one roof",
      "Duo Mix: a playlist for two, regularly updated with music you both enjoy",
      "Ad-free music listening, play offline, on-demand playback",
    ],
  },
  {
    title: "Family",
    price: "$22.50/month after offer period",
    numAcc: "Up to 6 accounts",
    features: [
      "6 Premium accounts for family members living under one roof",
      "Family Mix: a playlist for your family, regularly updated with music you all enjoy",
      "Block explicit music",
      "IMusic Kids: a separate app made just for kids",
      "Ad-free music listening, play offline, on-demand playback",
    ],
  },

  {
    title: "Student",
    price: "$7.49/month after offer period",
    numAcc: "1 account",
    features: [
      "Special discount for eligible students in university",
      "Listen to music ad-free",
      "Play anywhere - even offline",
      "On-demand playback",
    ],
  },
];
