import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/LoadingError/Loading";
import Toast from "../components/LoadingError/Toast";
import { login } from "../Redux/Actions/userActions";
import Message from "./../components/LoadingError/Error";

import GlobalStyle from "./Introduction/globalStyles";
import Navbar from "./Introduction/components/Navbar/Navbar";
import Footer from "./Introduction/components/Footer/Footer";
import LoginForm from "./Introduction/components/Login/LoginForm";

const Login = ({ history }) => {
  window.scrollTo(0, 0);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  // const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
  }, [userInfo, history]);

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(login(email, password));
  // };
  return (
    <>
      <Toast />
      <GlobalStyle />
      <Navbar></Navbar>
      {error && <Message variant="alert-danger">{error}</Message>}
          {loading && <Loading />}
      <LoginForm />
{/* 
      <div
        className="card shadow mx-auto"
        style={{ maxWidth: "380px", marginTop: "100px" }}
      >
        <div className="card-body">
          {error && <Message variant="alert-danger">{error}</Message>}
          {loading && <Loading />}
          <h1 className="card-title mb-4 text-center">THIS IS OWNER PAGE</h1>
          <h4 className="card-title mb-4 text-center">Sign in</h4>

          <form onSubmit={submitHandler}>
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <button type="submit" className="btn btn-primary w-100">
                Login
              </button>
            </div>
          </form>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default Login;
