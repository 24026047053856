import React, { useState } from "react";
import {
  FormColumn,
  Img,
  ImgWrapper,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormTitle,
  FormLabel,
  FormInputRow,
  FormMessage,
  NavItemBtn,
  NavBtnLink,
  ButtonContainer,
} from "./FormStyles";
import { Container, Button, TextWrapper, Column } from "../../globalStyles";
import { orange, white } from "../../Colors";
import validateLoginForm from "./validateLloginForm";
import ImageGallery from "react-image-gallery";

const Business = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log({ name });
    const resultError = validateLoginForm({ email, password });

    if (resultError !== null) {
      setError(resultError);
      return;
    }

    console.log(e);
    setName("");
    setPassword("");
    setError(null);
    setSuccess("Application was submitted!");
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      color: white,
      boxShadow: "0px 0px 8px white",
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  const formData = [
    {
      label: "UserName",
      value: name,
      onChange: (e) => setName(e.target.value),
      type: "text",
    },
    {
      label: "Password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
      type: "password",
    },
  ];

  const party1 = "https://slide-lokalai-update.s3.amazonaws.com/Slide1.JPG";
  const images = [
    {
      original: "Lokalai2024_short/Slide1.JPG",
      thumbnail: "Lokalai2024_short/Slide1.JPG",
    },
    {
      original: "Lokalai2024_short/Slide2.JPG",
      thumbnail: "Lokalai2024_short/Slide2.JPG",
    },
    {
      original: "Lokalai2024_short/Slide3.JPG",
      thumbnail: "Lokalai2024_short/Slide3.JPG",
    },
    {
      original: "Lokalai2024_short/Slide4.JPG",
      thumbnail: "Lokalai2024_short/Slide4.JPG",
    },
    {
      original: "Lokalai2024_short/Slide5.JPG",
      thumbnail: "Lokalai2024_short/Slide5.JPG",
    },
    {
      original: "Lokalai2024_short/Slide6.JPG",
      thumbnail: "Lokalai2024_short/Slide6.JPG",
    },
    {
      original: "Lokalai2024_short/Slide7.JPG",
      thumbnail: "Lokalai2024_short/Slide7.JPG",
    },
    {
      original: "Lokalai2024_short/Slide8.JPG",
      thumbnail: "Lokalai2024_short/Slide8.JPG",
    },
    {
      original: "Lokalai2024_short/Slide9.JPG",
      thumbnail: "Lokalai2024_short/Slide9.JPG",
    },
    {
      original: "Lokalai2024_short/Slide10.JPG",
      thumbnail: "Lokalai2024_short/Slide10.JPG",
    },
    {
      original: "Lokalai2024_short/Slide11.JPG",
      thumbnail: "Lokalai2024_short/Slide11.JPG",
    },
    {
      original: "Lokalai2024_short/Slide12.JPG",
      thumbnail: "Lokalai2024_short/Slide12.JPG",
    },
    {
      original: "Lokalai2024_short/Slide13.JPG",
      thumbnail: "Lokalai2024_short/Slide13.JPG",
    },
    {
      original: "Lokalai2024_short/Slide14.JPG",
      thumbnail: "Lokalai2024_short/Slide14.JPG",
    },
    {
      original: "Lokalai2024_short/Slide15.JPG",
      thumbnail: "Lokalai2024_short/Slide15.JPG",
    },
    {
      original: "Lokalai2024_short/Slide16.JPG",
      thumbnail: "Lokalai2024_short/Slide16.JPG",
    },
    {
      original: "Lokalai2024_short/Slide17.JPG",
      thumbnail: "Lokalai2024_short/Slide17.JPG",
    },
    {
      original: "Lokalai2024_short/Slide18.JPG",
      thumbnail: "Lokalai2024_short/Slide18.JPG",
    },
    {
      original: "Lokalai2024_short/Slide19.JPG",
      thumbnail: "Lokalai2024_short/Slide19.JPG",
    },
    {
      original: "Lokalai2024_short/Slide20.JPG",
      thumbnail: "Lokalai2024_short/Slide20.JPG",
    },
    {
      original: "Lokalai2024_short/Slide21.JPG",
      thumbnail: "Lokalai2024_short/Slide21.JPG",
    },
    {
      original: "Lokalai2024_short/Slide22.JPG",
      thumbnail: "Lokalai2024_short/Slide22.JPG",
    },
    {
      original: "Lokalai2024_short/Slide23.JPG",
      thumbnail: "Lokalai2024_short/Slide23.JPG",
    },
    {
      original: "Lokalai2024_short/Slide24.JPG",
      thumbnail: "Lokalai2024_short/Slide24.JPG",
    },
    {
      original: "Lokalai2024_short/Slide25.JPG",
      thumbnail: "Lokalai2024_short/Slide25.JPG",
    },
    {
      original: "Lokalai2024_short/Slide26.JPG",
      thumbnail: "Lokalai2024_short/Slide26.JPG",
    },
    {
      original: "Lokalai2024_short/Slide27.JPG",
      thumbnail: "Lokalai2024_short/Slide27.JPG",
    },
    {
      original: "Lokalai2024_short/Slide28.JPG",
      thumbnail: "Lokalai2024_short/Slide28.JPG",
    },
    {
      original: "Lokalai2024_short/Slide29.JPG",
      thumbnail: "Lokalai2024_short/Slide29.JPG",
    },
    {
      original: "Lokalai2024_short/Slide30.JPG",
      thumbnail: "Lokalai2024_short/Slide30.JPG",
    },
    {
      original: "Lokalai2024_short/Slide31.JPG",
      thumbnail: "Lokalai2024_short/Slide31.JPG",
    },
    {
      original: "Lokalai2024_short/Slide32.JPG",
      thumbnail: "Lokalai2024_short/Slide32.JPG",
    },
    {
      original: "Lokalai2024_short/Slide33.JPG",
      thumbnail: "Lokalai2024_short/Slide33.JPG",
    },
  ];

  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn>
            <FormTitle>Lokalai Business Plan</FormTitle>
            <TextWrapper
                  color="white"
                  size="clamp(1rem,3vw,1.3rem)"
                  lineHeight="1.1rem"
                  maxWidth="480px"
                  align="center"
                  margin="0 auto"
                  style={{ marginBottom: "1rem" }}
                >
                  This is a short version of Lokalai busines plan. You can
                  download a long version bellow.
                </TextWrapper>
                <div style={{ marginBottom: "50px" }}>
                  <ImageGallery
                    defaultImage={party1}
                    items={images}
                    showBullets={false}
                    showThumbnails={false}
                    showNav={true}
                    showFullscreenButton={true}
                    thumbnailPosition="left"
                  />
                </div>

            {!success && (
              <FormWrapper onSubmit={submitHandler}>
                {formData.map((el, index) => (
                  <FormInputRow key={index}>
                    <FormLabel>{el.label}</FormLabel>
                    <FormInput
                      type={el.type}
                      placeholder={`Enter your ${el.label.toLocaleLowerCase()}`}
                      value={el.value}
                      onChange={el.onChange}
                    />
                  </FormInputRow>
                ))}

                <Button
                  variants={buttonVariants}
                  whileHover="hover"
                  type="submit"
                >
                  Log In
                </Button>
              </FormWrapper>
            )}

            {error && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
                error
              >
                {error}
              </FormMessage>
            )}
            {success && (
              <>                
                <hr style={{ marginTop: "10px", border: "1px solid red" }} />
                <Column align="center" mt="10%" width="100%">
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Business Plan for INVESTORs
                  </TextWrapper>
                  <ButtonContainer
                    style={{
                      border: "1px dotted white",
                      padding: "10px",
                      maxWidth: "350px",
                    }}
                  >
                    <Button fontBig primary>
                      <a
                        href={"Lokalai - investor 2024.pptx"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        PPT
                      </a>
                    </Button>

                    <Button fontBig primary>
                      <a
                        href={"Lokalai - investor 2024.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "WHITE" }}
                      >
                        PDF
                      </a>
                    </Button>
                  </ButtonContainer>
                </Column>
                {/* <Column align="center" mt="10%" width="100%">
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Download busines plan (SHORT version)
                  </TextWrapper>
                  <ButtonContainer
                    style={{
                      border: "1px dotted white",
                      padding: "10px",
                      maxWidth: "350px",
                    }}
                  >
                    <Button fontBig primary>
                      <a
                        href={"Lokalai_short.pptx"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        PPT
                      </a>
                    </Button>

                    <Button fontBig primary>
                      <a
                        href={"Lokalai_short.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "WHITE" }}
                      >
                        PDF
                      </a>
                    </Button>
                  </ButtonContainer>
                </Column>
                <Column align="center" mt="5%" width="100%">
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Download busines plan (FULL version)
                  </TextWrapper>
                  <ButtonContainer
                    style={{
                      border: "1px dotted #DC1C13",
                      padding: "10px",
                      maxWidth: "350px",
                    }}
                  >
                    <Button fontBig primary>
                      <a
                        href={"Lokalai.pptx"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        PPT
                      </a>
                    </Button>

                    <Button fontBig primary>
                      <a
                        href={"Lokalai_full.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "WHITE" }}
                      >
                        PDF
                      </a>
                    </Button>
                  </ButtonContainer>
                </Column> */}
                <Column align="center" mt="5%" width="100%">
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Download full detail Business plan (Word file)
                  </TextWrapper>
                  <ButtonContainer
                    style={{
                      border: "1px dotted white",
                      padding: "10px",
                      maxWidth: "350px",
                    }}
                  >
                    <Button fontBig primary>
                      <a
                        href={"Lokalai_business_plan.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        PDF
                      </a>
                    </Button>
                  </ButtonContainer>
                </Column>
                <Column align="center" mt="5%" width="100%">
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Download financial plan
                  </TextWrapper>
                  <ButtonContainer
                    style={{
                      border: "1px dotted #DC1C13",
                      padding: "10px",
                      maxWidth: "350px",
                    }}
                  >
                    <Button fontBig primary>
                      <a
                        href={
                          "Lokalai_financing_v3_updarePriceandMarketing.xlsx"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        Excel
                      </a>
                    </Button>

                    <Button fontBig primary>
                      <a
                        href={
                          "Lokalai_financing_v3_updarePriceandMarketing.xlsx"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "WHITE" }}
                      >
                        PDF
                      </a>
                    </Button>
                  </ButtonContainer>
                </Column>
              </>
            )}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default Business;
