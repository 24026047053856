import React from 'react';
import GlobalStyle from './globalStyles';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Business from "./components/BusinessPlan/Business";
function Signup() {
	return (
		<>
			<GlobalStyle />
			<Navbar></Navbar>
			<Business /> 
			<Footer />		
		</>

	);
}

export default Signup;